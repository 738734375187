import React from 'react';
import { string, bool } from 'prop-types';
import { LANG_SE_SV } from '@/src/common/consts/lang';
import { THEME_WHITE } from '@/src/common/consts/theme';
import Image from '../../image';
import contentData from './data.json';

const Treatments = ({ lang, downloadhref, padBottom }) => {
  const content = contentData[lang];

  if (!content) {
    return null;
  }

  return (
    <section data-theme={THEME_WHITE} className="Treatments">
      <div className={`container container--md margin-top--lg ${padBottom ? 'margin-bottom--xl' : ''}`}>
        <div className="text--component margin-bottom--md text--center">
          <h2>{content.title}</h2>
          <p>{content.text}</p>
        </div>

        <div
          id="blockTreatments"
          className="grid grid-gap--md List List--card treatmentsList"
        >
          {content.cards && content.cards.map((card) => (
            <div key={card.title} className={`col col--12 col--sm-6 col--md-${content.cards.length === 4 ? '3' : '4'}`}>
              <div>
                <div>
                  <Image
                    filename={card.icon}
                    alt="alt"
                    classname="widthIcons"
                  />
                  <div>
                    <h3 className="margin-top--sm">{card.title}</h3>
                    <p>{card.data}</p>
                  </div>
                </div>
                <a
                  href={downloadhref}
                  className="margin-top--sm btn btn--sm btn--primary"
                  data-goal="Onboarding Viewed"
                >
                  {content.ctaText}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

Treatments.defaultProps = {
  downloadhref: '#',
  lang: LANG_SE_SV,
  padBottom: true,
};

Treatments.propTypes = {
  downloadhref: string,
  lang: string,
  padBottom: bool,
};

export default Treatments;
